<template>
  <b-modal
    :id="id"
    :title="title || 'Confirmation'"
    size="lg"
    @ok="$emit('ok')"
    @cancel="$emit('cancel')"
  >
    <slot />
    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="alert" @click="ok()">
        {{ okButtonText || 'OK' }}
      </b-button>
      <b-button size="sm" variant="primary" @click="cancel()">
        {{ cancelButtonText || 'Cancel' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'ConfirmationModal',

  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    okButtonText: {
      type: String,
    },
    cancelButtonText: {
      type: String,
    },
  },
}
</script>
