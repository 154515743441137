var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: _vm.id, title: _vm.title || "Confirmation", size: "lg" },
      on: {
        ok: function ($event) {
          return _vm.$emit("ok")
        },
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "alert" },
                  on: {
                    click: function ($event) {
                      return ok()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.okButtonText || "OK") + "\n    "
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cancelButtonText || "Cancel") +
                      "\n    "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }